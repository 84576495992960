import { useMeta } from '@nuxtjs/composition-api'
import { useI18n } from '~/src/composables/useI18n'

export const useTitle = function (title?: string, translate = true) {
  const domParser = new DOMParser()
  const { title: titleMeta } = useMeta()
  const translator = useI18n()

  function setTitle(title?: string, translate = true) {
    let parsedTitle = title?.trim()

    if (parsedTitle === undefined || parsedTitle.length === 0) {
      titleMeta.value = translator.t('common.title') as string
      return
    }

    if (translate) {
      parsedTitle = translator.t(parsedTitle) as string
    }

    parsedTitle = domParser.parseFromString(parsedTitle, 'text/html')
      .documentElement.textContent as string

    titleMeta.value = `${parsedTitle} – ${translator.t('common.title')}`
  }

  setTitle(title, translate)

  return { title: titleMeta, setTitle }
}

export default useTitle
